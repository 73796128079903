<template>
  <div>
    <v-row class="mt-5">
      <v-col class="d-flex" cols="12" md="2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" v-bind="attrs" v-on="on" outlined elevation="3">
              <v-row>
                <v-col cols="12" md="12" align-self="center">
                  <v-card-title class="justify-center">A valider</v-card-title>

                  <v-card-text class="text-center text-h2 h-full">{{ count.initiated }}</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <span
            >Ce sont les réclamations en attente d'ouverture (initiée) et non ouverte par l'administrateur ACPR.
          </span>
        </v-tooltip>
      </v-col>

      <v-col class="d-flex" cols="12" md="6">
        <v-card class="flex d-flex flex-column rounded-xl" outlined elevation="3">
          <v-row>
            <v-col cols="12" md="4" align-self="center">
              <v-card-title class="justify-center">Tâches en retard</v-card-title>
              <v-card-text class="text-center text-h2 align-center">{{ count.late }}</v-card-text>
            </v-col>
            <v-col cols="12" md="1">
              <v-divider vertical inset style="height: 10rem" class="mt-6 mr-10"></v-divider>
            </v-col>

            <v-col cols="12" md="7">
              <v-card-text v-if="complaintsTasksLate.length > 0" class="task-container">
                <v-list dense class="rounded-xl">
                  <v-list-item
                    v-for="(taskLate, index) in complaintsTasksLate"
                    v-bind:key="index"
                    link
                    :href="`/services/complaints/items?id=${taskLate.id_complaint}`"
                  >
                    <v-list-item-avatar size="40">
                      <v-avatar color="primary white--text" size="40">
                        <i style="font-size: 1.2rem" :class="['fa-solid fa-list-check fa-1x']"></i>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        Réclamation {{ taskLate.id_complaint_display }} - {{ taskLate.receiver }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--primary"
                        >A réaliser avant le : {{ taskLate.date_format }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle>Commentaire : {{ taskLate.comments_action }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <i class="fa-solid fa-warning EoleError--text fa-2x"></i>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-row v-else align="center" class="h-full">
                <v-col cols="12" md="12"> Aucune tâche n'est en retard pour le moment </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="d-flex" cols="12" md="2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" v-bind="attrs" v-on="on" outlined elevation="3">
              <v-row>
                <v-col cols="12" md="12" align-self="center">
                  <v-card-title class="justify-center">Tâches en cours</v-card-title>

                  <v-card-text class="text-center text-h2 h-full">{{ count.tasked }}</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <span>Ce sont le nombre de tâches totales sur les réclamations ouvertes. </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" v-bind="attrs" v-on="on" outlined elevation="3">
              <v-row>
                <v-col cols="12" md="12" align-self="center">
                  <v-card-title class="justify-center">Clôturées (2023)</v-card-title>

                  <v-card-text class="text-center text-h2 h-full">{{ count.ended }}</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <span>Ce sont le nombre de tâches totales sur les réclamations ouvertes. </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-card-title
          class="no-padding mt-5"
          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
        >
          <v-row>
            <v-col cols="12" md="6" class="text-left">
              <v-text-field style="width: 50%" v-model="selectedComplaint" label="Rechercher"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="text-right" align-self="center">
              <v-btn @click="dlCsv" small color="EoleGreen" class="white--text">
                <v-icon left>mdi-microsoft-excel</v-icon>
                Export csv</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-row>
          <v-col cols="12" md="9">
            <v-card min-height="250px" outlined elevation="3" class="flex d-flex flex-column rounded-xs">
              <v-data-table
                no-data-text="Aucune réclamation n'est disponible pour le moment"
                :search="selectedComplaint"
                :headers="headersComplaints"
                :items="complaints"
                item-key="name"
                class="w-full header-table rounded-xl"
                :items-per-page="20"
                disable-pagination
                hide-default-footer
                :custom-filter="filterOnlyCapsText"
              >
                <template v-slot:[`item.id_display`]="{ item }">
                  <a :href="`/services/complaints/items?id=${item.id}`" target="_blank">{{ item.id_display }}</a>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  {{ item.civility }} {{ item.lastname }} {{ item.firstname }}
                  <div v-if="item.social_name != null && item.social_name != ''">({{ item.social_name }})</div>
                </template>
                <template v-slot:[`item.collaborator`]="{ item }">
                  {{ item.lastname_creator }} {{ item.firstname_creator }}
                </template>
                <template v-slot:[`item.state`]="{ item }">
                  <div v-if="parseInt(item.id_state) === 1" color="EoleGreen">
                    <i class="fa-solid fa-lock-open mr-2 EoleGreen--text"></i>
                    <br />
                    <span> Réclamation ouverte</span>
                  </div>
                  <div v-else-if="parseInt(item.id_state) === 2" color="EoleGreen">
                    <i class="fa-solid fa-spinner fa-spin mr-2 EoleYellow--text"></i>
                    <br />
                    <span> Réclamation en cours</span>
                  </div>
                  <div v-else-if="parseInt(item.id_state) === 3" color="EoleGreen">
                    <i class="fa-solid fa-lock mr-2 EoleError--text"></i>
                    <br />
                    <span> Réclamation clôturée</span>
                  </div>
                  <div v-else-if="parseInt(item.id_state) === 4" color="EoleGreen">
                    <i class="fa-solid fa-history mr-2 EoleBlue--text"></i>
                    <br />
                    <span> Réclamation archivée</span>
                  </div>
                  <div v-else-if="parseInt(item.id_state) === 5" color="EoleBlue">
                    <v-chip small color="EoleBlue">Initiée</v-chip>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item, index }">
                  <v-menu right transition="slide-x-transition" close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="mailLoading[index]"
                        :disabled="mailLoading[index]"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        style=""
                        class="justify-center text-center"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item :href="`/services/complaints/items?id=${item.id}`" target="_blank">
                        <v-list-item-title>
                          <div class=""><i class="fa-solid fa-eye mr-2"></i> Voir la réclamation</div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" class="d-flex">
            <v-dialog width="600px" v-model="dialogAddComplaint">
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  min-height="250px"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  target="_blank"
                  link
                  elevation="3"
                  class="text-center justify-center flex d-flex flex-column rounded-xl"
                >
                  <v-card-text>
                    <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                  </v-card-text>
                  <v-card-text>Ajouter une nouvelle réclamation</v-card-text>
                </v-card>
              </template>

              <v-card>
                <v-card-title class="mb-n4">Nouvelle réclamation</v-card-title>
                <v-stepper v-model="stepperAddComplaint" flat>
                  <v-stepper-items class="mt-5">
                    <v-stepper-content step="1" class="no-padding">
                      <v-card-subtitle class="EoleYellow--text text-h6"
                        >Quand avez-vous réceptionné la réclamation ?</v-card-subtitle
                      >
                      <v-card-text>
                        <v-date-picker
                          ref="picker"
                          v-model="newComplaint.date"
                          :value="now"
                          full-width
                          locale="fr-fr"
                        ></v-date-picker>
                      </v-card-text>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card-text class="mt-2">
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field
                              v-model="newComplaint.social_name"
                              label="Raison sociale"
                              messages="*Si entreprise, sinon néant"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-select
                              label="Civilité"
                              outlined
                              dense
                              :items="genderItems"
                              item-text="text"
                              item-value="value"
                              v-model="newComplaint.gender"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="newComplaint.lastname" label="Nom" outlined dense></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="newComplaint.firstname" label="Prénom" outlined dense></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-text-field v-model="newComplaint.street" label="Adresse" outlined dense></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-autocomplete
                              messages="Entrez un code postal"
                              :search-input.sync="searchCity"
                              :items="cityItems"
                              outlined
                              v-model="newComplaint.city"
                              :value="newComplaint.zip"
                              label="Ville"
                              clearable
                              hide-details
                              hide-selected
                              dense
                              no-filter
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title> Entrez un code postal </v-list-item-title>
                                </v-list-item>
                              </template>

                              <template v-slot:item="{ item }">
                                <v-list-item-content>
                                  <v-list-item-title>{{ item }}</v-list-item-title>
                                </v-list-item-content>
                              </template></v-autocomplete
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="newComplaint.phone" label="Téléphone" outlined dense></v-text-field>
                          </v-col>
                          <v-col cols="12" md="8">
                            <v-text-field v-model="newComplaint.email" label="Email" outlined dense></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="newComplaint.reason"
                              label="Motif de la réclamation"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-textarea
                              v-model="newComplaint.comments"
                              label="Commentaires"
                              outlined
                              dense
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-select
                              label="Compagnie en cause"
                              outlined
                              dense
                              :items="insurersProposed"
                              item-text="name"
                              item-value="id"
                              v-model="newComplaint.id_insurer"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-select
                              label="Produit concerné"
                              outlined
                              dense
                              :items="productsProposed"
                              item-text="name"
                              item-value="id"
                              v-model="newComplaint.id_product"
                            >
                              <template v-slot:item="{ item }">
                                <i :class="`fa-solid fa-${item.icon} mr-2`"></i> {{ item.name }}
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field
                              v-model="newComplaint.contract_number"
                              label="Numéro de contrat"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-stepper-content>

                    <v-stepper-content step="4" class="no-padding">
                      <v-card-subtitle class="EoleYellow--text text-h6 mb-5"
                        >Quel est le niveau de complexité ?</v-card-subtitle
                      >
                      <v-card-text class="text-center justify-center align-center">
                        <v-row class="row-complexity text-center align-center d-inline-flex align-center">
                          <v-col cols="12" md="6" align-self="end">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-card
                                  @click="
                                    newComplaint.id_complexity = 1
                                    stepperAddComplaint++
                                  "
                                  v-on="on"
                                  outlined
                                  elevation="3"
                                  height="200"
                                  width="200"
                                  class="rounded-xl text-center flex d-flex flex-column justify-center align-center"
                                >
                                  <v-row align-content="center">
                                    <v-col cols="12" md="12" align-self="center">
                                      <v-card-text class="align-center">
                                        <i class="fa-solid fa-building fa-3x EoleBlue--text"></i>
                                      </v-card-text>
                                      <v-card-text> Simple</v-card-text>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </template>
                              <span>Ce niveau relève de la gestion en agence de la réclamation</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-card
                                  @click="
                                    newComplaint.id_complexity = 2
                                    stepperAddComplaint++
                                  "
                                  v-on="on"
                                  outlined
                                  elevation="3"
                                  height="200"
                                  width="200"
                                  class="rounded-xl text-center flex d-flex flex-column justify-center align-center"
                                >
                                  <v-row align-content="center">
                                    <v-col cols="12" md="12" align-self="center">
                                      <v-card-text class="align-center">
                                        <i class="fa-solid fa-building-circle-arrow-right fa-3x EoleBlue--text"></i>
                                      </v-card-text>
                                      <v-card-text> Complexe</v-card-text>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </template>
                              <span>Ce niveau relève de la gestion compagnie de la réclamation</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-stepper-content>

                    <v-stepper-content step="5" class="no-padding">
                      <v-card-subtitle class="EoleYellow--text text-h6 mb-5"
                        >Ajouter le courrier et les documents associés</v-card-subtitle
                      >
                      <v-row align="center" class="align-center text-center justify-center pa-5">
                        <v-col
                          cols="12"
                          md="6"
                          v-for="(document, indexDocument) in newComplaint.documents"
                          v-bind:key="indexDocument"
                        >
                          <div>
                            {{ document.name }} - {{ document.date }}
                            <span v-if="document === null && fileUrl === null"
                              >Aucun document n'est pour le moment disponible.</span
                            >
                            <iframe
                              v-else
                              id="lddcVisualisator"
                              style="width: 100%; height: 500px"
                              :src="filesUrl[indexDocument]"
                            ></iframe>
                          </div>
                          <v-card-text v-if="document.url != null">
                            <v-row align="center">
                              <v-col cols="12" md="6">
                                <v-btn color="EoleError" class="white--text" @click="deleteFile(document)">
                                  supprimer
                                </v-btn></v-col
                              >
                              <v-col cols="12" md="6">
                                <v-btn
                                  color="EoleBlue"
                                  class="white--text"
                                  @click="downloadFile(`${document.url}?cache=${Date.now()}`)"
                                >
                                  Télécharger
                                </v-btn></v-col
                              >
                            </v-row>
                          </v-card-text>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex">
                          <v-card
                            @click="openFileExplorer()"
                            link
                            outlined
                            @dragover.prevent
                            class="drop-zone flex d-flex flex-column justify-center text-center"
                          >
                            <v-card-text>
                              <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                            </v-card-text>
                            <v-card-text>Ajout d'un document</v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-card-text> </v-card-text>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
                <v-card-text> </v-card-text>
                <v-card-actions>
                  <v-btn v-if="stepperAddComplaint < 5 && stepperAddComplaint > 1" text @click="stepperAddComplaint--"
                    >précédent</v-btn
                  >
                  <v-btn text v-else @click="dialogAddComplaint = false">annuler</v-btn>

                  <v-spacer></v-spacer>
                  <v-btn text v-if="stepperAddComplaint < 5 && stepperAddComplaint != 4" @click="stepperAddComplaint++">
                    suivant</v-btn
                  >
                  <v-btn
                    v-else-if="stepperAddComplaint === 5"
                    class="white--text"
                    color="EoleGreen"
                    @click="postComplaint(newComplaint)"
                  >
                    <v-icon left>mdi-check</v-icon>
                    Ajouter</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
import config from "@/views/config/config"
import moment from "moment"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
import * as XLSX from "xlsx"

init("user_gUunL4KW2ImPaGngWHYhP")
export default {
  data: () => ({
    complaintsTasksLate: [],
    count: {
      initiated: 0,
      late: 0,
      tasked: 0,
      ended: 0,
    },
    now: new Date().toISOString().substr(0, 10), // Obtient la date du jour au format YYYY-MM-DD
    selectedComplaint: "",
    genderItems: [
      { text: "Monsieur", value: "M" },
      { text: "Madame", value: "F" },
    ],
    searchCity: null,
    mailLoading: [],
    stepperAddComplaint: 1,
    newComplaint: {
      social_name: "",
      lastname: "",
      firstname: "",
      gender: "",
      email: "",
      phone: "",
      street: "",
      city: "",
      zip: "",
      reason: "",
      comments: "",
      id_insurer: "",
      date: "",
      product: "",
      id_complexity: "",
      contract_number: "",
      documents: [],
      documentsInformations: [],
    },
    cityItems: [],
    insurersProposed: [],
    productsProposed: [],
    dialogAddComplaint: false,
    tab: 1,
    complaints: [],
    headersComplaints: [
      { text: "Identifiant", value: "id_display" },
      { text: "Nom du réclamant", value: "name" },
      { text: "Compagnie", value: "company_name" },
      { text: "Entrée par", value: "collaborator" },

      { text: "État de la réclamation", value: "state" },
      { text: "Actions", value: "actions" },
    ],
    filesUrl: [],
  }),

  async created() {
    this.fetchAgencyComplaints().finally(() => {
      this.calculComplaints()
      console.log(this.complaints)
    })
    this.fetchInsurers()
    this.fetchProducts()
  },
  watch: {
    searchCity(val) {
      console.log(val)
      if (val.length === 5) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.newComplaint.zip = val
            })
            .catch(err => {
              console.log(err)
            })

          // for (let index = 0; index < data.length; index++) {
          //   this.cityItems.push(data[index].nomCommune)
          // }

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }

      // // Items have already been loaded
      // if (this.items.length > 0) return

      // this.isLoading = true

      // // Lazily load input items
      // fetch('https://api.coingecko.com/api/v3/coins/list')
      //   .then(res => res.clone().json())
      //   .then(res => {
      //     this.items = res
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => (this.isLoading = false))
    },
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    async upload(idComplaint) {
      const agencyName = localStorage.getItem("agency").toLowerCase()
      this.newComplaint.documentsInformations.forEach(doc => {
        doc.url = `${config.ged}/${agencyName}/complaints/${idComplaint}/${doc.name}`
      })
      let logs = []
      const completeName = localStorage.getItem("complete_name")

      let formData = new FormData()

      this.newComplaint.documents.forEach(async document => {
        formData = new FormData()

        logs.push({
          info: `Le document ${document.name} à été ajoutés par ${completeName}`,
          datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
          type: "edit",
        })

        formData.append("document", document.data)
        formData.append("documentName", document.name)
        formData.append("agencyName", agencyName)
        formData.append("idComplaint", idComplaint)
        formData.append("logs", JSON.stringify(logs))
        formData.append("data", JSON.stringify(this.newComplaint.documentsInformations))
        formData.append("place", "complaintDocuments")

        try {
          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            body: formData,
          })

          if (res.ok) {
            this.newComplaint.documents = [] // Clear the array after successful upload
            this.newComplaint.documentsInformations = []

            this.filesUrl = []

            this.dialogAddTask = false
          } else {
            alert("Erreur lors de l'upload des documents")
          }
        } catch (err) {
          console.error(err)
          alert("Erreur lors de l'upload des documents")
        }
      })
    },

    openFileExplorer() {
      const agencyName = localStorage.getItem("agency").toLowerCase()
      const input = document.createElement("input")

      input.type = "file"
      // Accept only word docx pdf and images
      input.accept = ".pdf, .docx, .doc, .jpg, .jpeg, .png, .gif, .bmp, .tiff, image/*"
      input.multiple = true

      input.addEventListener("change", event => {
        const files = event.target.files
        const newDocuments = []

        for (let i = 0; i < files.length; i++) {
          const documentData = {
            name: files[i].name,
            data: files[i],
          }

          this.newComplaint.documents.push({
            data: documentData.data,
            name: documentData.name,
          })

          this.newComplaint.documentsInformations.push({
            name: documentData.name,
            type: documentData.data.type,
            size: this.formatBytes(documentData.data.size),
            date: new Date().toLocaleDateString(),
          })

          this.filesUrl.push(URL.createObjectURL(documentData.data))
        }
      })

      input.click()
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    },
    async putComplaintState(item, newState, index) {
      this.mailLoading[index] = true
      let complementaryStateInfo = ""

      switch (newState) {
        case 1:
          complementaryStateInfo = "ouvert"
          break
        case 2:
          complementaryStateInfo = "fermée"
          break
        case 3:
          complementaryStateInfo = "cloturée"
          break
        case 4:
          complementaryStateInfo = "archivée"
          break
      }
      const username = localStorage.getItem("complete_name")

      let log = item.logs

      if (newState === 1) {
        emailjs
          .send("service_5p49t7p", "template_lhfaiu7", {
            lastname: item.lastname,
            firstname: item.firstname,
            civility: item.civility,
            //from_name: email,
            to_email: item.email,
          })
          .finally(() => {
            this.mailLoading[index] = false
            this.$toast.success("Email envoyé")

            log.push({
              info: `La réclamation a été modifiée pour un état ${complementaryStateInfo} par ${username} le ${moment().format(
                "DD/MM/YYYY à HH:mm",
              )}`,
              datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
            })
            log.push({
              info: `Un email a été envoyé à ${item.lastname} ${item.firstname} pour l'informer que sa réclamation a été ouverte`,
              datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
            })

            const formData = new FormData()

            formData.append("place", "putComplaintState")
            formData.append("idComplaint", item.id)
            formData.append("idState", newState)
            formData.append("logs", JSON.stringify(log))

            // using fetch to post data
            fetch(`${config.apiUri}/php/pages/agencies_complaint.php`, {
              method: "POST",
              body: formData,
            })
              .finally(() => {
                this.fetchAgencyComplaints()
                this.dialogAddComplaint = false
                this.$toast.success("Modifié avec succès")
              })
              .catch(() => {
                this.$toast.error("Une erreur est survenue")
              })
          })
          .catch(() => {
            this.$toast.error("Erreur lors de l'envoi de l'email")
            log.push({
              info: `Une erreur est survenue lors de l'envoi de l'email à ${item.lastname} ${item.firstname} pour l'informer que sa réclamation a été ouverte`,
              datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
            })
          })
      } else {
        log.push({
          info: `La réclamation a été modifiée pour un état ${complementaryStateInfo} par ${username} le ${moment().format(
            "DD/MM/YYYY à HH:mm",
          )}`,
          datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
        })
        const formData = new FormData()

        formData.append("place", "putComplaintState")
        formData.append("idComplaint", item.id)
        formData.append("idState", newState)
        formData.append("logs", JSON.stringify(log))

        // using fetch to post data
        fetch(`${config.apiUri}/php/pages/agencies_complaint.php`, {
          method: "POST",
          body: formData,
        })
          .finally(() => {
            this.fetchAgencyComplaints()
            this.dialogAddComplaint = false
            this.mailLoading[index] = false
            this.$toast.success("Modifié avec succès")
          })
          .catch(() => {
            this.$toast.error("Une erreur est survenue")
          })
      }

      console.log(item)
    },
    async postComplaint(newComplaint) {
      let logs = []

      logs.push({
        info: `La réclamation a été initiée par ${localStorage.getItem("complete_name")} le ${moment().format(
          "DD/MM/YYYY à HH:mm",
        )}`,
        datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
      })

      const idAccountCreator = localStorage.getItem("user_id")
      const formData = new FormData()
      formData.append("idAgency", localStorage.getItem("agency_id"))
      formData.append("socialname", newComplaint.social_name)
      formData.append("lastname", newComplaint.lastname)
      formData.append("firstname", newComplaint.firstname)
      formData.append("gender", newComplaint.gender)
      formData.append("email", newComplaint.email)
      formData.append("phone", newComplaint.phone)
      formData.append("street", newComplaint.street)
      formData.append("city", newComplaint.city)
      formData.append("zip", newComplaint.zip)
      formData.append("reason", newComplaint.reason)
      formData.append("comments", newComplaint.comments)
      formData.append("idInsurer", newComplaint.id_insurer)
      formData.append("idProduct", newComplaint.id_product)
      formData.append("contractNumber", newComplaint.contract_number)
      formData.append("date", newComplaint.date)
      formData.append("idComplexity", newComplaint.id_complexity)
      formData.append("idAccountCreator", idAccountCreator)
      formData.append("logs", JSON.stringify(logs))
      formData.append("data", JSON.stringify(newComplaint.documentsInformations))

      formData.append("place", "addComplaint")

      // using fetch to post data
      fetch(`${config.apiUri}/php/pages/agencies_complaint.php`, {
        method: "POST",
        body: formData,
      })
        .then(async res => {
          let data = await res.json()
          console.log(data)

          this.upload(data.id)
        })
        .finally(() => {
          this.fetchAgencyComplaints()
          this.dialogAddComplaint = false
          this.$toast.success("Collaborateur ajouté avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    async fetchProducts() {
      this.loading = true
      let idAgency = localStorage.getItem("agency_id")

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.products.forEach(element => {
          this.productsProposed.push({
            id: element.id,
            name: element.name,
            icon: element.icon,
          })
        })

        this.loading = false
        this.fetch = true
      } catch (e) {
        console.error(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchInsurers() {
      this.loading = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/insurers/approved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.insurersProposed.push({
            id: element.id,
            name: element.name,
          })
        })

        this.insurersProposed.sort(el => {
          return el.name
        })

        this.loading = false
        this.fetch = true
      } catch (e) {
        console.error(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    convertToCSV(objArray) {
      const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray
      let str = ""

      // En-têtes de colonnes
      const headers = Object.keys(array[0])
      str += headers.join(",") + "\r\n"

      // Valeurs des propriétés
      for (let i = 0; i < array.length; i++) {
        let line = ""
        line += array[i].lastname + ","
        line += array[i].firstname + ","
        line += array[i].reason
        str += line + "\r\n"
      }

      return str
    },

    dlCsv() {
      const headers = [
        "Date de la réception",
        "Nom du réclamation",
        "Compagnie",
        "Motif",
        "Complexité",
        "Statut de la réclamation",
      ]

      // Exemple d'objet contenant les données
      let data = []
      let unique = []
      this.complaints.forEach(complaint => {
        let logs = []
        complaint.logs.forEach(log => {
          logs.push(`• (${log.datetime}) ${log.info}\n`)
        })

        unique.push({
          "Date de la réception": complaint.date,
          "Nom du réclamation": complaint.lastname + " " + complaint.firstname,
          Compagnie: complaint.company_name,
          Motif: complaint.reason,
          Complexité: complaint.complexity,
          "Statut de la réclamation": complaint.state,
          "Suivi de la réclamation": logs.join("\n"),
        })

        complaint.tasks.forEach(task => {
          unique.push({
            //get the number of task by the index of this task
            "": `Tâche n°${complaint.tasks.indexOf(task) + 1}`,
            "Date de fin": task.date_end,
            Destinataire: task.receiver,
            Action: task.action,
          })
        })
      })

      const worksheet = XLSX.utils.json_to_sheet(unique, { header: headers })

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, `Réclamations ${localStorage.getItem("agency")}`)

      XLSX.writeFile(wb, `export_reclamations_${moment().format("DD-MM-YYYY")}.xlsx`)
    },
    calculComplaints() {
      this.complaints.forEach(complaint => {
        console.log(complaint)

        if (complaint.tasks.length > 0) {
          complaint.tasks.forEach(task => {
            task.id_complaint = complaint.id
            task.id_complaint_display = complaint.id_display

            if (parseInt(complaint.id_state) === 1) {
              this.count.tasked++
              let anteriatory = false
              moment(this.now).isAfter(task.date_end) ? (anteriatory = true) : (anteriatory = false)
              task.anteriatory = anteriatory

              if (task.anteriatory) {
                this.count.late++
                this.complaintsTasksLate.push(task)
              }
            }
          })
        }

        if (parseInt(complaint.id_state) === 3) {
          this.count.ended++
        }

        console.log("endend", this.count.ended)
      })
    },
    async fetchAgencyComplaints() {
      this.count = {
        initiated: 0,
        late: 0,
        tasked: 0,
        ended: 0,
      }

      let now = moment().format("YYYY-MM-DD")
      this.complaints = []
      let headers = new Headers()
      let agencyId = localStorage.getItem("agency_id")

      // get 2 letters of the agency name
      let agencyName = localStorage.getItem("agency")
      let agencyNameShort = agencyName.substring(0, 3).toUpperCase()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/agencies/${agencyId}/complaints`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          data.complaints.forEach(complaint => {
            if (complaint.tasks.length > 0) {
              complaint.tasks.forEach(task => {
                task.date_format = moment(task.date_end).format("Do MMMM YYYY")
              })
            }
            this.complaints.push({
              id: complaint.id,
              id_display: `#${agencyNameShort}-${complaint.id}`,
              lastname: complaint.lastname,
              firstname: complaint.firstname,
              date: moment(complaint.date_received).format("DD/MM/YYYY"),
              company_name: complaint.company_name,
              reason: complaint.reason,
              id_state: complaint.id_complaint_state,
              logs: complaint.logs === null ? [] : JSON.parse(complaint.logs),
              gender: complaint.gender,
              civility: complaint.gender === "M" ? "Monsieur" : "Madame",
              email: complaint.email,
              social_name: complaint.social_name,
              lastname_creator: complaint.lastname_creator,
              firstname_creator: complaint.firstname_creator,
              lastname_validator: complaint.lastname_validator,
              firstname_validator: complaint.firstname_validator,
              tasks: complaint.tasks === null ? [] : complaint.tasks,
              state: complaint.state,
              complexity: complaint.complexity,
            })
            this.mailLoading.push(false)
            parseInt(complaint.id_complaint_state) === 5 ? (this.count.initiated += 1) : null
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style>
.row-complexity {
  max-width: 450px;
}
.v-data-table tr {
  text-align: center !important;
}
.theme--light.header-table th {
  background-color: #001f47 !important;
  color: #fcc03c !important;
  font-size: 14px !important;
  text-align: center !important;
}
.theme--light.header-table td {
  text-align: center !important;
}
.transparent-bg {
  background-color: transparent !important;
}
.no-padding {
  padding: 0 !important;
}

.task-container {
  max-height: 220px;
  overflow-y: auto;
  padding: 0 !important;
}

.task-container::-webkit-scrollbar {
  width: 10px;
  height: 10px !important;
  background-color: #f5f5f5; /* Couleur de fond de la barre de défilement */
}
.task-container::-webkit-scrollbar-track {
  background-color: #fcc03c; /* Couleur de fond de la barre de défilement */
}

.task-container::-webkit-scrollbar-thumb {
  background-color: #001f47; /* Couleur de la barre de défilement */
  border-radius: 20px;
}
</style>
